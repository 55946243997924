import { render, staticRenderFns } from "./coaches_experts.vue?vue&type=template&id=1d41fdbc&scoped=true&"
import script from "./coaches_experts.vue?vue&type=script&lang=js&"
export * from "./coaches_experts.vue?vue&type=script&lang=js&"
import style0 from "./coaches_experts.vue?vue&type=style&index=0&id=1d41fdbc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d41fdbc",
  null
  
)

export default component.exports