<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <!-- @hide="hideDropdownFormFilter"
          @show="showDropdownFormFilter"
          @toggle="toggleDropdownFormFilter" -->
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form>
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã bệnh nhân"
                    placeholder="Nhập mã bệnh nhân"
                    name="code"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-input
                    label="Tên bệnh nhân"
                    placeholder="Nhập tên bệnh nhân"
                    name="name"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
                <b-col>
                  <basic-select
                    label="Giới tính"
                    placeholder="--- Chọn giới tính ---"
                    name="gender"
                    :options="isGenderOpts"
                    :value.sync="filter.gender"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col>
                      <label for="age">Độ tuổi</label>
                      <b-row>
                        <b-col>
                          <basic-input
                            placeholder="Từ"
                            name="startAge"
                            inputType="number"
                            v-validate="'numeric'"
                            data-vv-as="Tuổi từ"
                            :state="validateState('startAge')"
                            :invalidFeedback="errors.first('startAge')"
                            :value.sync="filter.ageStart"
                          ></basic-input>
                        </b-col>
                        <b-col>
                          <basic-input
                            placeholder="Đến"
                            name="endAge"
                            v-validate="'numeric'"
                            data-vv-as="Tuổi đến"
                            :state="validateState('endAge')"
                            :invalidFeedback="errors.first('endAge')"
                            inputType="number"
                            :value.sync="filter.ageEnd"
                          ></basic-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <basic-select
                    label="Tình trạng bệnh tiểu đường"
                    placeholder="--- Chọn tình trạng bệnh ---"
                    name="diabetesStatus"
                    :options="[
                      { name: 'Tiền tiểu đường', id: 1 },
                      { name: 'Loại 1', id: 2 },
                      { name: 'Loại 2', id: 3 },
                    ]"
                    :value.sync="filter.diabetesStatus"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Quốc gia"
                    placeholder="--- Chọn quốc gia ---"
                    name="nation"
                    :apiPath="'/Division/Nations'"
                    :searchField="'searchTerm'"
                    :value.sync="filter.nationId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Tỉnh thành"
                    placeholder="--- Chọn tỉnh thành ---"
                    name="province"
                    :apiPath="'/Division/Provinces'"
                    :searchField="'searchTerm'"
                    :searchParams="{ nationId: filter.nationId }"
                    :value.sync="filter.provinceId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Quận / huyện"
                    placeholder="--- Chọn quận / huyện ---"
                    name="district"
                    :apiPath="'/Division/Dictricts'"
                    :searchField="'searchTerm'"
                    :searchParams="{ provinceId: filter.provinceId }"
                    :value.sync="filter.districtId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Phường xã"
                    placeholder="--- Chọn phường xã ---"
                    name="wards"
                    :apiPath="'/Division/Wards'"
                    :searchField="'searchTerm'"
                    :searchParams="{ districtId: filter.districtId }"
                    :value.sync="filter.wardId"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Gói dịch vụ"
                    placeholder="--- Chọn gói dịch vụ ---"
                    name="servicePack"
                    :options="[]"
                    disabled
                    :value.sync="filter.servicePack"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
                <b-col>
                  <basic-date-picker
                    label="Thời gian tạo từ"
                    placeholder="Nhập thời gian tạo từ lúc"
                    name="startDateOfCreation"
                    :value.sync="filter.createDatetime"
                  />
                </b-col>
                <b-col>
                  <basic-select
                    label="Bệnh viện"
                    placeholder="--- Chọn ---"
                    name="hospital"
                    disabled
                    :options="[]"
                    :value.sync="filter.hospital"
                    :solid="false"
                    :allowEmpty="true"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <basic-input
                    label="Số điện thoại"
                    placeholder="Số điện thoại"
                    name="phoneNumber"
                    inputType="number"
                    v-validate="'numeric|max:10'"
                    data-vv-as="Số điện thoại"
                    :state="validateState('phoneNumber')"
                    :invalidFeedback="errors.first('phoneNumber')"
                    :value.sync="filter.phoneNumber"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-checkbox-group
                    v-model="filter.inactive"
                    :options="[
                      { text: 'Lọc người dùng Inactive', value: false },
                    ]"
                  ></b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.prevent="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
      </template>
    </basic-subheader>
    <b-container fluid class="user-app-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <template-table
                :column="column"
                :data="data"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                :searchAction="false"
                @search="searchRequest"
                @reset="resetRequest"
                @sortBy="sortRequest"
              >
                <template v-slot:body="{ item }">
                  <td>
                    <div class="d-flex align-items-center mt-5">
                      <avatar
                        size="40px"
                        :text="item.fullName"
                        :src="item.imageUrl && item.imageUrl.url"
                        :rounded="true"
                      ></avatar>
                      <div class="d-flex flex-column ml-5">
                        <p
                          class="mb-0"
                          style="
                            font-weight: 600;
                            font-size: 13px;
                            color: #515356;
                          "
                        >
                          {{ item.fullName }}
                        </p>
                        <p
                          class="mt-2 mb-0"
                          style="font-size: 12px; color: #888c9f"
                        >
                          Mã số: {{ item.code || 0
                          }}{{
                            item.membershipType
                              ? `, ${item.membershipType}`
                              : ', Thành viên Cơ Bản'
                          }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td style="width: 20px">
                    <action-dropdown
                      :value="item"
                      :show_copy="false"
                      :show_view="false"
                      :show_delete="false"
                      :show_edit="false"
                      :boundary="data.length > 2 ? 'scrollParent' : 'window'"
                    >
                      <div class="" style="padding: 10px">
                        <p
                          class="text-primary text-nowrap pointer"
                          @click.prevent="viewDashboard(item)"
                        >
                          <span class="svg-icon">
                            <inline-svg
                              src="/media/svg/icons/Neolex/Basic/bar-chart.svg"
                            />
                          </span>
                          Xem Dashboard bệnh nhân
                        </p>
                        <b-dropdown-divider> </b-dropdown-divider>
                        <p
                          class="text-primary text-nowrap pointer mb-0"
                          @click="viewItem(item)"
                        >
                          <span class="svg-icon">
                            <inline-svg
                              src="/media/svg/icons/Neolex/Basic/info.svg"
                            />
                          </span>
                          Xem thông tin bệnh nhân
                        </p>
                        <!-- <p class="text-primary text-nowrap pointer">
                          <span class="svg-icon">
                            <inline-svg
                              src="/media/dashboard/Emoji/file-download.svg"
                            />
                          </span>
                          Xuất dữ liệu
                        </p> -->
                      </div>
                    </action-dropdown>
                  </td>
                  <td>
                    {{ item.age }}
                  </td>
                  <td>
                    {{ item.gender }}
                  </td>
                  <td>
                    {{ item.phoneNumber }}
                  </td>
                  <td>
                    {{
                      $moment(item.createDatetime * 1000).format('DD/MM/YYYY')
                    }}
                  </td>
                  <td>
                    {{ item.nation && item.nation.name }}
                  </td>
                  <td>
                    {{ item.province && item.province.name }}
                  </td>
                  <td>
                    {{ item.district && item.district.name }}
                  </td>
                  <td>
                    {{ item.ward && item.ward.name }}
                  </td>
                </template>
              </template-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <dashboard-modal :user="userDetail" :id="userId" />
  </div>
</template>

<script>
export default {
  name: 'CoachesExperts',
  data() {
    return {
      userId: null,
      isToggleDropdownFormFilter: false,
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        code: null,
        name: null,
        gender: null,
        ageStart: null,
        ageEnd: null,
        hospital: null,
        diabetesStatus: null,
        createDatetime: null,
        nationId: null,
        provinceId: null,
        districtId: null,
        wardId: null,
        inactive: null,
      },
      sort: {
        by: null,
        order: null,
      },
      isActiveOpts: [
        { id: true, name: 'Active' },
        { id: false, name: 'Inactive' },
      ],
      isGenderOpts: [
        { id: 1, name: 'Nam' },
        { id: 2, name: 'Nữ' },
      ],
      column: [
        {
          key: 'name',
          label: 'Bệnh nhân',
          sortable: true,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
        {
          key: 'age',
          label: 'Độ tuổi',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới tính',
          sortable: false,
        },
        {
          key: 'phone',
          label: 'Số điện thoại',
          sortable: false,
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'country',
          label: 'Quốc gia',
          sortable: false,
        },
        {
          key: 'province',
          label: 'Tỉnh Thành',
          sortable: false,
        },
        {
          key: 'district',
          label: 'Quận',
          sortable: false,
        },
        {
          key: 'wards',
          label: 'Phường/Xã',
          sortable: false,
        },
      ],
      data: [],
      userDetail: {},
    };
  },
  computed: {
    searchParams() {
      return {
        name: this.filter.name,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        ageStart: this.filter.ageStart,
      };
    },
  },
  components: {
    'dashboard-modal': () => import('./modalDashboard'),
  },
  watch: {
    paging: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        this.veeFields[ref].dirty &&
        this.veeFields[ref].validated
      ) {
        return this.veeFields[ref].valid;
      }
      return null;
    },
    viewDashboard(item) {
      this.userDetail = item;
      this.userId = item.id;
      setTimeout(() => {
        this.$bvModal.show('dashboard-modal');
      }, 500);
    },
    hideDropdownFormFilter(bvEvent) {
      if (this.isToggleDropdownFormFilter) {
        bvEvent.preventDefault();
      }
    },
    showDropdownFormFilter(bvEvent) {
      if (!this.isToggleDropdownFormFilter) {
        bvEvent.preventDefault();
      }
    },
    toggleDropdownFormFilter() {
      this.isToggleDropdownFormFilter = !this.isToggleDropdownFormFilter;
    },
    pagingAction() {
      this.loadData();
    },
    searchRequest() {
      this.loadData();
      document.body.click();
    },
    resetRequest() {
      this.filter.code = null;
      this.filter.name = null;
      this.filter.gender = null;
      this.filter.ageStart = null;
      this.filter.ageEnd = null;
      this.filter.createDatetime = null;
      this.filter.nationId = null;
      this.filter.provinceId = null;
      this.filter.districtId = null;
      this.filter.wardId = null;
      this.filter.inactive = [];
      this.filter.phoneNumber = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });

      this.loadData();
      document.body.click();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    viewItem(item) {
      this.$router.push({
        name: 'user_app_view',
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this.$router.push({
        name: 'user_app_detail',
        params: {
          form_type: 'edit',
          id: item.id,
        },
      });
    },
    deleteItem() {},
    loadData() {
      this.$store.commit('context/setLoading', true);
      let inactive = this.filter.inactive?.[0];
      let createDate = this.filter.createDatetime
        ? this.$moment(this.filter.createDatetime).format('X')
        : null;
      let query = {
        ...this.searchParams,
        ...this.filter,
        inactive: inactive,
        createDatetime: createDate,
      };
      this.$api
        .get('Patient', {
          params: query,
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}
.pointer {
  cursor: pointer;
}
</style>
